import React from 'react'
import { useIntl } from 'react-intl'
import { graphql } from 'gatsby'
//
import {
  ContentfulBarista,
  ContentfulSettings,
  Site,
  ContentfulTipConnection,
  ContentfulRecipeConnection,
  ContentfulTechniqueConnection
} from '~generated/gatsby.types'
import { MainLayout } from '~layout'
import { buildPageMetaTags } from '~utils'
import { getRandomIndex } from '~utils/common'
import { Tip, ContentCarousel, TertiaryHero, InstagramBlock } from '~components/page-components'
import { BaristaProfile } from '~components/template-components'
import theme from '~theme'

type PageContextType = {
  id: number
  node_locale: string
}

type PageQuery = {
  site: Site
  contentfulBarista: ContentfulBarista
  contentfulSettings: ContentfulSettings
  allContentfulTip: ContentfulTipConnection
  allContentfulRecipe: ContentfulRecipeConnection
  allContentfulTechnique: ContentfulTechniqueConnection
}

type PageProps = {
  data: PageQuery
  pageContext: PageContextType
}

const Barista = ({ data }: PageProps) => {
  const intl = useIntl()
  const {
    site,
    contentfulBarista,
    allContentfulTip,
    allContentfulRecipe,
    allContentfulTechnique,
    contentfulSettings
  } = data
  const { id, heroImage, flowboxId, nickname, instagramUrl } = contentfulBarista
  const tips = allContentfulTip && allContentfulTip.edges && allContentfulTip.edges.map(edge => ({ ...edge.node }))
  const baristaTip = tips && tips[getRandomIndex(tips)]

  const {
    baristaContentCarouselTitle,
    baristaContentCarouselShortDescription,
    baristaContentCarouselCta,
    baristaInstagramShortDescription
  } = contentfulSettings

  const techniques = allContentfulTechnique.edges.map(edge => {
    const baristas = edge?.node?.barista?.map((value: any) => value.id) || []

    return {
      ...edge.node,
      matches: baristas.includes(id) ? 1 : 0
    }
  })

  const recipes = allContentfulRecipe.edges.map(edge => {
    const baristas = edge?.node?.barista?.map((value: any) => value.id) || []

    return {
      ...edge.node,
      matches: baristas.includes(id) ? 1 : 0
    }
  })

  const recipesAndTechniques = [...recipes, ...techniques]
    .sort((a, b) => b.publishedDate - a.publishedDate)
    .sort((a, b) => b.matches - a.matches || b.publishedDate - a.publishedDate)
    .slice(0, 8)

  const instagramBlockTitle = intl.formatMessage({ id: 'barista.instagramBlock.title' }, { nickname })

  const instagramBlockDescription: any = {
    shortDescription: baristaInstagramShortDescription?.baristaInstagramShortDescription
  }

  const instagramBlockCta: any = {
    externalUrl: instagramUrl,
    title: intl.formatMessage({ id: 'barista.instagramBlock.button' })
  }

  return (
    <MainLayout metaTags={buildPageMetaTags(contentfulBarista, site)} contentfulSettings={contentfulSettings}>
      <TertiaryHero image={heroImage} />

      <BaristaProfile barista={contentfulBarista} />

      {baristaTip && <Tip {...baristaTip} variant="secondary" />}

      <InstagramBlock
        id={id}
        flowboxId={flowboxId}
        title={instagramBlockTitle}
        shortDescription={instagramBlockDescription}
        cta={instagramBlockCta}
      />

      <ContentCarousel
        id={`barista-${id}`}
        title={baristaContentCarouselTitle}
        shortDescription={baristaContentCarouselShortDescription}
        contents={recipesAndTechniques}
        cta={baristaContentCarouselCta}
        bgColorOverride={theme.colors.white}
      />
    </MainLayout>
  )
}

export default Barista

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!) {
    contentfulBarista(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      __typename
      id
      title
      nickname
      location
      signature
      position
      facebookUrl
      flowboxId
      twitterUrl
      instagramUrl
      description {
        json
      }
      heroImage {
        bynder: bynderAsset {
          fluid(maxWidth: 1920, quality: 80) {
            ...QueryImageFluid
          }
        }
        local: localAsset {
          fluid(maxWidth: 1920, quality: 80) {
            ...QueryImageFluid
          }
        }
      }
      image {
        bynder: bynderAsset {
          fixed(width: 166, height: 166, toFormat: JPG, quality: 80, cropFocus: FACE) {
            ...QueryImageFixed
          }
        }
        local: localAsset {
          fixed(width: 166, height: 166, toFormat: JPG, quality: 80, cropFocus: FACE) {
            ...QueryImageFixed
          }
        }
      }
      metaTags {
        title
        description
        noindex
        nofollow
        keywords
        canonical
      }
    }

    allContentfulTip(filter: { barista: { id: { eq: $id } } }) {
      edges {
        node {
          ...FragmentTip
        }
      }
    }

    allContentfulRecipe(sort: { fields: publishedDate, order: DESC }) {
      edges {
        node {
          __typename
          id
          title
          slug
          publishedDate
          shortDescription {
            shortDescription
          }
          categories {
            title
            id
          }
          barista {
            id
          }
          image {
            bynder: bynderAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
            local: localAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
          }
        }
      }
    }

    allContentfulTechnique(sort: { fields: publishedDate, order: DESC }) {
      edges {
        node {
          __typename
          id
          title
          slug
          publishedDate
          shortDescription {
            shortDescription
          }
          categories {
            title
            id
          }
          barista {
            id
          }
          image {
            bynder: bynderAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
            local: localAsset {
              fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
                ...QueryImageFluid
              }
            }
          }
        }
      }
    }

    contentfulSettings(locale: { eq: $node_locale }) {
      ...QuerySiteSettings
    }

    site {
      ...FragmentSite
    }
  }
`
